/*noinspection CssUnknownTarget*/
@import "@genstackio/react-admin-core/assets/css/styles.css";

.theme-killbills {
    --color-clear: 255, 255, 255;/* #FFFFFF */
    --color-danger: 166, 67, 55;/* #A64337 */
    --color-dark: 28, 26, 26;/* #1C1A1A */
    --color-disabled: 206, 204, 195;/* #CECCC3 */
    --color-info: 200, 200, 200;/* #C8C8C8 */
    --color-light: 243, 243, 243;/* #F3F3F3 */
    --color-primary: 41, 37, 59;/* #37A76F */
    --color-secondary: 39, 40, 122;/* #27287A */
    --color-success: 223, 238, 222;/* #DFEEDE */
    --color-warning: 199, 166, 24;/* #C7A618 */
}